import { ChevronRightIcon } from "@heroicons/react/solid";
import Link from "next/link";
import { ReactNode } from "react";
import { cn } from "ui";

export type BreadCrumbProps = {
  icon?: React.ReactNode;
  pages: Array<{
    name: string;
    href: string;
    query?: Record<string, string | string[] | undefined>;
    current: boolean;
  }>;
  className?: string;
  lastPageAction?: ReactNode;
};

const BreadCrumb: React.FC<BreadCrumbProps> = ({
  pages,
  icon,
  className = "",
  lastPageAction,
}) => {
  return (
    <div className={cn("flex justify-between pt-6", className)}>
      <div className="flex flex-row items-center">
        {icon && (
          <span className="rounded-md bg-foundations-subtler p-1">{icon}</span>
        )}

        <nav className="flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-2">
            {pages.map((page, index) => (
              <li key={page.name + index}>
                <div className="flex items-center">
                  {index !== 0 ? (
                    <ChevronRightIcon
                      className="mr-2 h-5 w-5 shrink-0 text-foundations-subtler"
                      aria-hidden="true"
                    />
                  ) : null}
                  {!!lastPageAction && index === pages.length - 1 ? (
                    lastPageAction
                  ) : (
                    <Link
                      href={{
                        pathname: page.href,
                        query: page.query ?? {},
                      }}
                      passHref
                    >
                      <a
                        className={cn(
                          "inline-block py-2 text-sm text-info hover:text-blue-600 hover:underline",
                          icon && "ml-2",
                          page.current &&
                            "pointer-events-none font-medium text-foundations-bolder"
                        )}
                        aria-current={page.current ? "page" : undefined}
                      >
                        {page.name}
                      </a>
                    </Link>
                  )}
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
};
export default BreadCrumb;
