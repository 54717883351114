import Head from "next/head";
import styles from "../styles/Home.module.css";
import BreadCrumb, { BreadCrumbProps } from "./BreadCrumb";
import { forwardRef } from "react";

type LayoutProps = {
  metaTitle: string;
  breadCrumbPages?: BreadCrumbProps["pages"];
  breadCrumbAction?: React.ReactNode;
  children: React.ReactNode;
  actions?: React.ReactNode;
};

const Layout = forwardRef<HTMLElement, LayoutProps>(
  (
    { children, breadCrumbPages, breadCrumbAction, metaTitle, actions },
    ref
  ) => (
    <main
      ref={ref}
      className="relative h-full flex-1 overflow-y-auto overflow-x-hidden focus:outline-none"
    >
      <div className="py-6">
        <div className="mx-auto flex max-w-7xl flex-wrap items-center justify-between gap-2 px-4 sm:px-6 md:px-8">
          <div className="flex flex-row items-center">
            {breadCrumbPages && (
              <BreadCrumb
                pages={breadCrumbPages}
                className="pt-0"
                lastPageAction={breadCrumbAction}
              />
            )}
          </div>

          {actions}
        </div>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <Head>
            <title>
              {metaTitle || "Dashboard"} - {process.env.NEXT_PUBLIC_APP_TITLE}
            </title>
            <meta name="description" content="Generated by MoneyHash" />
            <link rel="icon" href="/favicon.ico" />
          </Head>

          <main className={styles.main}>{children}</main>
        </div>
      </div>
    </main>
  )
);
Layout.displayName = "Layout";

export default Layout;
