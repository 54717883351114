import { cn, cva, OverflowText, type VariantProps } from "ui";
import { twoFixedDigit } from "../../../utils/twoFixedDigit";

const badgeVariants = cva("text-foundations-subtle", {
  variants: {
    variant: {
      success: "text-success",
      danger: "text-danger",
    },
  },
});

interface RateItemProps extends Required<VariantProps<typeof badgeVariants>> {
  img?: string;
  label: React.ReactNode;
  percentage: number;
  diffPercentage: number | null;
}
export function RateItem({
  img,
  label,
  percentage,
  diffPercentage,
  variant,
}: RateItemProps) {
  return (
    <div className="flex items-center">
      {img && (
        <img src={img} alt="" className="mr-2 h-8 w-8 rounded object-contain" />
      )}
      <OverflowText>
        <p className="text-foundations-boldest">{label}</p>
      </OverflowText>

      <div
        className={cn(
          "ml-auto flex items-center space-x-2 text-xs",
          badgeVariants({ variant })
        )}
      >
        <span>{twoFixedDigit(percentage)}%</span>
        {!!diffPercentage && (
          <span
            data-variant={variant}
            className="rounded-md border px-1 py-0.5 data-[variant=danger]:border-danger-subtle data-[variant=success]:border-success-subtle data-[variant=danger]:bg-danger-subtler data-[variant=success]:bg-success-subtler"
          >
            {diffPercentage > 0 && "+"}
            {twoFixedDigit(diffPercentage)}%
          </span>
        )}
      </div>
    </div>
  );
}
