import { useEffect, useState } from "react";
import { useUserContext } from "../contexts/UserProvider";
import { safeLocalStorage } from "../utils/safeLocalStorage";

/**
 * State hook with extra functionality to mark if needed
 * to be saved in localStorage as default value or not
 *
 * @example Account selection
 * ```ts
 * const [
 * selectedAccount,
 * setSelectedAccount,
 * { saveAsDefault, setSaveAsDefault }
 * ] = useStateWithDefaultUpdater<DropdownOption<string>>("default-account", ["", ""]);
 * ```
 * `selectedAccount` `setSelectedAccount` normal `useState` return
 * `{ saveAsDefault, setSaveAsDefault }` is boolean state and updater for checkbox or any logic you want
 *
 * @returns [state, setState, { saveAsDefault, setSaveAsDefault }]
 */

export function useStateWithDefaultUpdater<TType>(
  baseKey: string,
  defaultValue: TType
) {
  const { id, email } = useUserContext();
  const key = `${id}_${email?.split("@")[0]}_${baseKey}`;

  const [state, setState] = useState<TType>(() => {
    const storedValue = safeLocalStorage.getItem(key);
    if (!storedValue) return defaultValue;

    try {
      return JSON.parse(storedValue);
    } catch (error) {
      return defaultValue;
    }
  });
  const [saveAsDefault, setSaveAsDefault] = useState(false);

  useEffect(() => {
    if (!saveAsDefault) return;
    safeLocalStorage.setItem(key, JSON.stringify(state));
  }, [saveAsDefault, state, key]);

  return [state, setState, { saveAsDefault, setSaveAsDefault }] as const;
}
