import { cn, OverflowText } from "ui";

interface SubheadingProps extends React.HTMLAttributes<HTMLHeadingElement> {}

export function Subheading({ className, ...props }: SubheadingProps) {
  return (
    <OverflowText>
      <p
        className={cn(
          "mt-1 text-xl font-bold text-foundations-boldest",
          className
        )}
        {...props}
      />
    </OverflowText>
  );
}
