import {
  SwitchHorizontalIcon,
  CashIcon,
  ProgrammingArrowsIcon,
} from "icons/outline";
import { DashboardMetrics } from "../types";
import { Skeleton } from "ui";

export function CountMetrics({
  providers,
  methods,
  flows,
}: {
  providers: DashboardMetrics["providers"];
  methods: DashboardMetrics["methods"];
  flows: DashboardMetrics["flows"];
}) {
  return (
    <div className="mt-5 flex flex-wrap gap-4 rounded-md border border-foundations-subtle bg-foundations-subtlest p-4">
      <Metric
        Icon={SwitchHorizontalIcon}
        label="Connected Providers"
        count={providers?.connected}
      />
      <Metric
        Icon={CashIcon}
        label="Connected Methods"
        count={methods?.connected}
      />
      <Metric
        Icon={ProgrammingArrowsIcon}
        label="Published Flows"
        count={flows?.published}
      />
    </div>
  );
}

const Metric = ({
  Icon,
  label,
  count,
}: {
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  label: string;
  count: number;
}) => (
  <div className="flex flex-1 items-center gap-2 rounded-lg border border-foundations-subtle bg-foundations-default p-4 text-foundations-boldest">
    <div className="flex h-9 w-9 items-center justify-center rounded-md bg-foundations-subtler">
      <Icon className="h-5 w-5" />
    </div>
    <p className="whitespace-nowrap text-xs font-medium uppercase tracking-wider">
      {label}
    </p>

    <span className="ml-auto text-xl font-bold">{count}</span>
  </div>
);

export const CountMetricsSkeleton = () => (
  <div className="mt-5 flex flex-wrap gap-4 rounded-md border border-foundations-subtle bg-foundations-subtlest p-4">
    {Array.from({ length: 3 }, (_, idx) => (
      <div
        key={idx}
        className="flex min-w-[280px] flex-1 items-center gap-2 rounded-lg border border-foundations-subtle bg-foundations-default p-4"
      >
        <Skeleton className="h-9 w-9 rounded-md" />
        <Skeleton className="max-w-[171px] flex-1" />
      </div>
    ))}
  </div>
);
