import { Tab as HeadlessTab } from "@headlessui/react";

interface Tab {
  label: string;
  Panel: () => JSX.Element;
  disabled?: boolean;
}
interface TabsProps {
  tabs: Tab[];
  className?: string;
  onTabClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tab: Tab
  ) => void;
}

export function Tabs({ tabs, className, onTabClick }: TabsProps) {
  return (
    <HeadlessTab.Group as="div" className={className}>
      <HeadlessTab.List className="-mx-4 flex border-b border-b-foundations-subtle">
        {tabs.map((tab) => (
          <HeadlessTab
            key={tab.label}
            disabled={tab.disabled}
            onClick={(e) => {
              onTabClick?.(e, tab);
            }}
            className="flex-1 border-b-2 border-b-transparent pb-2.5 pl-2 pt-1 text-foundations-subtle focus:border-b-foundations-subtle focus:outline-none enabled:hover:border-b-foundations-subtle disabled:cursor-not-allowed aria-selected:text-foundations-bolder enabled:aria-selected:border-b-info-bold enabled:aria-selected:focus:border-b-info-boldest"
          >
            {tab.label}
          </HeadlessTab>
        ))}
      </HeadlessTab.List>

      <HeadlessTab.Panels className="-mx-4 -mb-4 p-4">
        {tabs.map(({ Panel }, idx) => (
          <HeadlessTab.Panel
            key={idx}
            className="flex-1 focus:outline-none focus:ring-2 focus:ring-info-bold"
          >
            <Panel />
          </HeadlessTab.Panel>
        ))}
      </HeadlessTab.Panels>
    </HeadlessTab.Group>
  );
}
