import React from "react";
import { ChevronDownIcon } from "icons/outline";
import { useClickAway } from "react-use";
import { format, startOfDay, endOfDay } from "date-fns";
import { subDays } from "date-fns";
import {
  Calendar,
  type DateRange,
  Menu,
  MenuTrigger,
  MenuContent,
  MenuItem,
} from "ui";

const OPTIONS = [
  {
    label: "Today",
    start: () => startOfDay(new Date()),
    end: () => endOfDay(new Date()),
  },
  {
    label: "Last week",
    start: () => startOfDay(subDays(new Date(), 6)),
    end: () => endOfDay(new Date()),
  },
  {
    label: "Last 30 days",
    start: () => startOfDay(subDays(new Date(), 29)),
    end: () => endOfDay(new Date()),
  },
  {
    label: "Custom",
  },
] as const;

interface DurationPickerProps {
  startDate?: Date;
  setDateRange: (dateRange?: DateRange) => void;
  endDate?: Date;
  maxDate: Date;
}
const DurationPicker = ({
  startDate,
  endDate,
  setDateRange,
  maxDate,
}: DurationPickerProps) => {
  const [option, setOption] = React.useState("Today");
  const [isOpen, setIsOpen] = React.useState(false);
  const ref = React.useRef(null);

  useClickAway(ref, () => {
    setIsOpen(false);
  });

  const handleSelect = (item: (typeof OPTIONS)[number]) => {
    setOption(item.label);

    if (item.label !== "Custom") {
      setDateRange({ from: item.start(), to: item.end() });
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    handleSelect(OPTIONS[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Menu open={isOpen} onOpenChange={setIsOpen} modal={false}>
      <MenuTrigger className="group flex items-center whitespace-nowrap rounded-md border border-foundations-bold bg-foundations-default px-4 py-2 text-sm text-foundations-bolder data-[state=open]:border-info-bold data-[state=open]:ring-1 data-[state=open]:ring-info-bold">
        {option !== "Custom"
          ? option
          : startDate && endDate
            ? `${format(startDate, "MM-dd")} to ${format(endDate, "MM-dd")}`
            : option}
        <ChevronDownIcon
          width={20}
          height={20}
          className={`ml-2 text-foundations-subtle transition-transform group-focus:text-info ${
            isOpen ? "rotate-180 text-info" : "rotate-0"
          }`}
        />
      </MenuTrigger>

      <MenuContent
        className="max-h-[auto] w-auto min-w-[var(--radix-dropdown-menu-trigger-width)] p-1"
        hideWhenDetached
      >
        {OPTIONS.map((item, i) => (
          <MenuItem
            key={i}
            className="justify-center"
            onSelect={(e) => {
              e.preventDefault();
              handleSelect(item);
            }}
          >
            {item.label}
          </MenuItem>
        ))}

        {option === "Custom" && (
          <Calendar
            mode="range"
            selected={{ from: startDate, to: endDate }}
            onSelect={setDateRange}
            toDate={maxDate}
          />
        )}
      </MenuContent>
    </Menu>
  );
};

export default DurationPicker;
