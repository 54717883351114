import { Card, Skeleton, cn } from "ui";

type State = "loading" | "error";

export const HorizontalBarsChartSkeleton = ({
  state,
  className,
}: {
  state: State;
  className?: string;
}) => (
  <Card disableHover className={className}>
    <Skeleton className="mb-4 w-24" />
    {state === "loading" && (
      <div className="flex flex-col space-y-9">
        <Skeleton className="w-11/12" />
        <Skeleton className="w-8/12" />
        <Skeleton className="w-1/2" />
        <Skeleton className="w-1/3" />
      </div>
    )}
    {state === "error" && <FailedToFetchData />}
  </Card>
);

export const ChartSkeleton = ({ state }: { state: State }) => (
  <Card disableHover className="">
    <Skeleton className="mb-4 w-24" />
    {state === "loading" && (
      <div className="relative flex h-52 flex-col justify-between">
        <div className="h-px w-full bg-foundations-subtlest" />
        <div className="h-px w-full bg-foundations-subtlest" />
        <div className="h-px w-full bg-foundations-subtlest" />
        <div className="h-px w-full bg-foundations-subtlest" />
        <div className="h-px w-full bg-foundations-subtlest" />

        <div className="absolute inset-x-0 bottom-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="103"
            viewBox="0 0 480 103"
            fill="none"
            preserveAspectRatio="none"
          >
            <path
              d="M43.7668 37.0055L0 48.9292V103H480V0L435.874 17.7911L392.825 28.4657L348.7 56.2198L305.65 70.0969L263.318 56.2198L218.117 50.5267H174.35L131.3 35.5822L87.5336 42.4802L43.7668 37.0055Z"
              className="fill-[theme(backgroundColor.foundations-subtlest)]"
            />
          </svg>
        </div>
      </div>
    )}
    {state === "error" && <FailedToFetchData />}
  </Card>
);

export const CircleChartSkeleton = ({
  state,
  size = 170,
  strokeWidth = 10,
}: {
  state: State;
  size?: number;
  strokeWidth?: number;
}) => (
  <Card disableHover className="flex flex-col">
    <Skeleton className="mb-4 w-24" />
    <div
      className={cn(
        "relative flex self-center",
        state === "loading" && "animate-pulse"
      )}
    >
      <svg width={size} height={size}>
        <circle
          className="stroke-neutral-100"
          fill="none"
          strokeWidth={strokeWidth}
          r={size / 2 - strokeWidth}
          cx={size / 2}
          cy={size / 2}
        />
      </svg>

      {state === "error" && (
        <div className="absolute inset-0 flex flex-col items-center justify-center space-y-1.5">
          <div className="flex scale-[0.8] flex-col items-center">
            <ErrorStateIcon />
            <p className="mt-2 text-xs font-medium text-foundations-boldest">
              Failed to fetch data
            </p>
          </div>
        </div>
      )}
    </div>
  </Card>
);

export const FailedToFetchData = () => (
  <div className="relative flex h-52 flex-col justify-between">
    <div className="h-px w-full bg-foundations-subtlest" />
    <div className="h-px w-full bg-foundations-subtlest" />
    <div className="h-px w-full bg-foundations-subtlest" />
    <div className="h-px w-full bg-foundations-subtlest" />
    <div className="h-px w-full bg-foundations-subtlest" />

    <div className="absolute inset-0 flex flex-col items-center justify-center space-y-2.5">
      <ErrorStateIcon />
      <p className="text-xs font-medium text-foundations-boldest">
        Failed to fetch data
      </p>
    </div>
  </div>
);

export const ErrorStateIcon = ({ className }: { className?: string }) => (
  <svg
    width="112"
    height="84"
    viewBox="0 0 112 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M56.737 84C79.9329 84 98.737 65.196 98.737 42C98.737 18.804 79.9329 0 56.737 0C33.541 0 14.737 18.804 14.737 42C14.737 65.196 33.541 84 56.737 84Z"
      fill="#F4F5F5"
    />
    <g filter="url(#filter0_d_18659_4808)">
      <path
        d="M8.19264 29.5547H103.393C104.328 29.5547 105.225 29.9262 105.886 30.5874C106.547 31.2486 106.919 32.1455 106.919 33.0806V50.7102C106.919 51.6454 106.547 52.5422 105.886 53.2034C105.225 53.8647 104.328 54.2362 103.393 54.2362H8.19264C7.25751 54.2362 6.36068 53.8647 5.69944 53.2034C5.0382 52.5422 4.66672 51.6454 4.66672 50.7102V33.0806C4.66672 32.1455 5.0382 31.2486 5.69944 30.5874C6.36068 29.9262 7.25751 29.5547 8.19264 29.5547V29.5547Z"
        fill="white"
      />
    </g>
    <path
      d="M53.3241 35.1953H34.9893C33.8209 35.1953 32.8737 36.1425 32.8737 37.3109C32.8737 38.4793 33.8209 39.4264 34.9893 39.4264H53.3241C54.4925 39.4264 55.4397 38.4793 55.4397 37.3109C55.4397 36.1425 54.4925 35.1953 53.3241 35.1953Z"
      fill="#E5E6E9"
    />
    <path
      d="M66.0173 44.3633H34.9891C33.8207 44.3633 32.8736 45.3104 32.8736 46.4788C32.8736 47.6472 33.8207 48.5944 34.9891 48.5944H66.0173C67.1857 48.5944 68.1328 47.6472 68.1328 46.4788C68.1328 45.3104 67.1857 44.3633 66.0173 44.3633Z"
      fill="#E5E6E9"
    />
    <path
      d="M27.2343 41.8951C27.2343 36.2479 22.6563 31.6699 17.0091 31.6699C11.3619 31.6699 6.78391 36.2479 6.78391 41.8951C6.78391 47.5423 11.3619 52.1203 17.0091 52.1203C22.6563 52.1203 27.2343 47.5423 27.2343 41.8951Z"
      fill="#A6AEBB"
    />
    <g clipPath="url(#clip0_18659_4808)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0896 41.9993C22.0896 44.7484 19.8609 46.977 17.1118 46.977C14.3626 46.977 12.134 44.7484 12.134 41.9993C12.134 39.2501 14.3626 37.0215 17.1118 37.0215C19.8609 37.0215 22.0896 39.2501 22.0896 41.9993ZM17.734 44.4881C17.734 44.8318 17.4554 45.1104 17.1118 45.1104C16.7681 45.1104 16.4896 44.8318 16.4896 44.4881C16.4896 44.1445 16.7681 43.8659 17.1118 43.8659C17.4554 43.8659 17.734 44.1445 17.734 44.4881ZM17.1118 38.8882C16.7681 38.8882 16.4896 39.1667 16.4896 39.5104V41.9993C16.4896 42.3429 16.7681 42.6215 17.1118 42.6215C17.4554 42.6215 17.734 42.3429 17.734 41.9993V39.5104C17.734 39.1667 17.4554 38.8882 17.1118 38.8882Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_18659_4808"
        x="0.435607"
        y="27.4391"
        width="110.714"
        height="33.1439"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.11556" />
        <feGaussianBlur stdDeviation="2.11556" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_18659_4808"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_18659_4808"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_18659_4808">
        <rect
          width="12.4444"
          height="12.4444"
          fill="white"
          transform="translate(10.8896 35.7773)"
        />
      </clipPath>
    </defs>
  </svg>
);
