import { cn } from "ui";

interface HeadingProps extends React.HTMLAttributes<HTMLHeadingElement> {}

export function Heading({ className, ...props }: HeadingProps) {
  return (
    <h2
      className={cn(
        "text-xs font-medium uppercase tracking-wider text-foundations-boldest",
        className
      )}
      {...props}
    />
  );
}
