export type ErrorResponse = {
  response?: { data: { status: { errors: Record<string, string>[] } } };
};

const isErrorResponse = (err: unknown): err is ErrorResponse => {
  return !!(err as ErrorResponse)?.response?.data?.status?.errors;
};

export function parseErrorsToArray(err: unknown) {
  return Object.values(parseErrorsToObject(err));
}

export function parseErrorsToObject(err: unknown) {
  if (!isErrorResponse(err) || !err?.response) return {};

  const errors = err.response.data.status.errors;

  if (!errors.length) return {};

  const _errors = errors.reduce((acc, curr) => {
    return { ...acc, ...curr };
  }, {} as Record<string, string>);

  return _errors;
}
