import React, { useState, useEffect, useMemo } from "react";
import Link from "next/link";
import {
  Button,
  Card,
  DropdownSearch,
  type DropdownOption,
  Chart,
  ChartJS,
  CircleChart,
  DiffPercentageBadge,
  HorizontalBarsChart,
  Checkbox,
  formatCurrency,
  formatNumber,
  cn,
  Tooltip,
  type DateRange,
  getColorFromCssVariable,
  EmptyStateIllustration4,
} from "ui";

import { axiosApiInstance } from "../pages/api/axios";
import DurationPicker from "../components/DurationPicker";
import { useUserContext } from "../contexts/UserProvider";

import Layout from "../components/Layout";
import { twoFixedDigit } from "../utils/twoFixedDigit";
import {
  Heading,
  Subheading,
  Tabs,
  RateItem,
  CountMetrics,
  CountMetricsSkeleton,
  HorizontalBarsChartSkeleton,
  CircleChartSkeleton,
  ChartSkeleton,
  type DashboardMetrics,
} from "../features/insights";
import { useStateWithDefaultUpdater } from "../hooks/useStateWithDefaultUpdater";
import { useMoneyHashQuery } from "../hooks/useMoneyHashQuery";
import { parseErrorsToArray } from "../utils/parseErrors";
import { useAnalytics } from "../features/analytics/analyticsProvider";
import { format } from "date-fns";
import { useFeatureFlags } from "../hooks/query";
import { InformationCircleIcon } from "icons/outline";

export default function Dashboard() {
  const userContext = useUserContext();
  const username = userContext?.email?.split?.("@")?.[0];

  const [
    selectedAccounts,
    setSelectedAccounts,
    { saveAsDefault, setSaveAsDefault },
  ] = useStateWithDefaultUpdater<DropdownOption<string>[]>(
    "default-accounts",
    []
  );

  return (
    <Layout metaTitle="Home">
      <div className="w-full">
        <p className="mt-0.5 text-lg font-bold text-foundations-boldest">
          Welcome {username}
        </p>

        {selectedAccounts.length > 0 ? (
          <Insights
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}
            saveAsDefault={saveAsDefault}
            setSaveAsDefault={setSaveAsDefault}
          />
        ) : (
          <Onboarding
            selectedAccounts={selectedAccounts}
            setSelectedAccounts={setSelectedAccounts}
            saveAsDefault={saveAsDefault}
            setSaveAsDefault={setSaveAsDefault}
          />
        )}
      </div>
    </Layout>
  );
}

const Onboarding = ({
  selectedAccounts,
  setSelectedAccounts,
  saveAsDefault,
  setSaveAsDefault,
}: {
  selectedAccounts: DropdownOption<string>[];
  setSelectedAccounts: React.Dispatch<
    React.SetStateAction<DropdownOption<string>[]>
  >;
  saveAsDefault: boolean;
  setSaveAsDefault: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className="mt-20 flex flex-col items-center text-center">
      <EmptyStateIllustration4 />
      <h2 className="mt-1 font-medium text-foundations-boldest">
        Select an account to start
      </h2>
      <p className="text-foundations-subtle">
        Filter by account and date to fetch data on this page
      </p>
      <DropdownSearch
        multiple
        withConfirmation
        className="mt-8 max-w-xs"
        label="Select account"
        selected={selectedAccounts}
        onSelect={setSelectedAccounts}
        getOptions={getAccountsWithSearch}
      />
      <label className="mt-4 flex items-center space-x-2 text-sm font-medium text-foundations-bolder">
        <Checkbox
          checked={saveAsDefault}
          onChange={(e) => setSaveAsDefault(e.target.checked)}
        />
        <span>Set as default account to preview data here </span>
      </label>
    </div>
  );
};

const Insights = ({
  selectedAccounts,
  setSelectedAccounts,
  saveAsDefault,
  setSaveAsDefault,
}: {
  selectedAccounts: DropdownOption<string>[];
  setSelectedAccounts: React.Dispatch<
    React.SetStateAction<DropdownOption<string>[]>
  >;
  saveAsDefault: boolean;
  setSaveAsDefault: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const { from: startDate, to: endDate } = dateRange || {};
  const { analytics } = useAnalytics();
  const { featureFlags } = useFeatureFlags();

  const {
    data: dashboardMetrics,
    isLoading,
    isError,
  } = useMoneyHashQuery<DashboardMetrics>({
    queryKey: [
      "insights",
      { account: selectedAccounts, from: startDate, to: endDate },
    ],
    queryFn: ({ signal }) =>
      getDashboardData({
        startDate: startDate!,
        endDate: endDate!,
        accountIds: selectedAccounts.map((account) => account[0]).join(","),
        signal: signal,
      }),
    enabled: Boolean(startDate && endDate),
  });

  const showSkeleton = isLoading || isError || !dashboardMetrics;
  const skeletonState = isLoading ? "loading" : "error";
  const [isSearchingForAllAccounts, setIsSearchingForAllAccounts] =
    useState(false);

  useEffect(() => {
    async function getIsAllAccountsValue() {
      const totalAccounts = await getAccountsCount();
      setIsSearchingForAllAccounts(totalAccounts === selectedAccounts.length);
    }

    getIsAllAccountsValue();
  }, [selectedAccounts]);

  useEffect(() => {
    import("chartjs-plugin-zoom").then((mod) => ChartJS.register(mod.default));
  }, []);

  return (
    <>
      {showSkeleton ? (
        <CountMetricsSkeleton />
      ) : (
        <CountMetrics
          providers={dashboardMetrics?.providers}
          flows={dashboardMetrics?.flows}
          methods={dashboardMetrics?.methods}
        />
      )}
      <div className="mt-5 flex flex-wrap items-center justify-between gap-4">
        <h2 className="flex items-center font-medium text-foundations-boldest">
          Overview of your payment hub
          {featureFlags.new_dashboard_summaries ? (
            <span className="ml-1 font-normal text-foundations-subtle">
              <Tooltip
                  content={"Data is updated with a 24h delay"}
                  variant="light"
                >
                  <div className="bg-foundations-subtelst-02 rounded-full p-1">
                    <InformationCircleIcon className="h-5 w-5" />
                  </div>
                </Tooltip>
            </span>
          ) : null}
        </h2>
        <div className="flex flex-1 items-center justify-end space-x-4">
          <DropdownSearch
            multiple
            className="max-w-[225px]"
            label="Select account"
            selected={selectedAccounts}
            onSelect={(selected: DropdownOption<string>[]) => {
              analytics.track("insights_set_account", {
                selected: selected,
              });
              setSelectedAccounts(selected);
            }}
            getOptions={getAccountsWithSearch}
            withConfirmation
            ExtraFooterAction={
              <label className="mb-4 flex items-center space-x-2 text-sm font-medium text-foundations-bolder">
                <Checkbox
                  checked={saveAsDefault}
                  onChange={(e) => setSaveAsDefault(e.target.checked)}
                />
                <span>Set as default account</span>
              </label>
            }
          />

          <DurationPicker
            startDate={startDate}
            endDate={endDate}
            setDateRange={(range) => {
              setDateRange(range);

              analytics.track("insights_set_duration", {
                selected: {
                  from: range?.from ? format(range?.from, "dd/M/yyyy") : "",
                  to: range?.to ? format(range?.to, "dd/M/yyyy") : "",
                },
              });
            }}
            maxDate={new Date()}
          />
        </div>
      </div>
      <div
        className={cn(
          "mt-4 grid grid-cols-1 gap-8",
          isSearchingForAllAccounts
            ? "md:grid-cols-2 lg:grid-cols-3"
            : "md:grid-cols-2"
        )}
      >
        {showSkeleton ? (
          <>
            <HorizontalBarsChartSkeleton state={skeletonState} />
            <HorizontalBarsChartSkeleton state={skeletonState} />
            {isSearchingForAllAccounts && (
              <HorizontalBarsChartSkeleton
                state={skeletonState}
                className="md:col-span-2 lg:col-span-1"
              />
            )}
          </>
        ) : (
          <>
            <ProvidersMetrics
              providers={dashboardMetrics.providers}
              currency={dashboardMetrics.currency}
            />
            <MethodsMetrics
              methods={dashboardMetrics.methods}
              currency={dashboardMetrics.currency}
            />
            {isSearchingForAllAccounts && (
              <AccountsMetrics
                revenueByAccount={dashboardMetrics.revenue_by_account}
                currency={dashboardMetrics.currency}
              />
            )}
          </>
        )}
      </div>

      <div className="mt-8 grid grid-cols-1 gap-8 lg:grid-cols-2">
        {showSkeleton ? (
          <>
            <ChartSkeleton state={skeletonState} />
            <ChartSkeleton state={skeletonState} />
          </>
        ) : (
          <>
            <RevenueMetrics
              selectedAccounts={selectedAccounts}
              revenue={dashboardMetrics.revenue_by_day}
              currency={dashboardMetrics.currency}
              totalRevenue={dashboardMetrics.total_revenue}
            />
            <TransactionsMetrics
              selectedAccounts={selectedAccounts}
              transactions={dashboardMetrics.transactions_by_day}
              totalTransactions={dashboardMetrics.total_transactions}
            />
          </>
        )}
      </div>

      <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        {showSkeleton ? (
          <>
            <CircleChartSkeleton state={skeletonState} />
            <CircleChartSkeleton state={skeletonState} />
            <CircleChartSkeleton state={skeletonState} />
          </>
        ) : (
          <>
            <DeclineRateMetrics
              rate={dashboardMetrics.rate_metrics.decline}
              selectedAccounts={selectedAccounts}
            />
            <SuccessRateMetrics rate={dashboardMetrics.rate_metrics.success} />
            <RefundRateMetrics rate={dashboardMetrics.rate_metrics.refund} />
          </>
        )}
      </div>
    </>
  );
};

const ProvidersMetrics = ({
  providers,
  currency,
}: {
  providers: DashboardMetrics["providers"];
  currency: DashboardMetrics["currency"];
}) => {
  const { analytics } = useAnalytics();
  const highestProvider = providers.metrics[0];

  return (
    <Card disableHover className="flex flex-col">
      <div className="mb-4">
        <Heading>Providers</Heading>
        {highestProvider && (
          <Subheading>
            {twoFixedDigit(highestProvider.percentage)}% {highestProvider.label}
          </Subheading>
        )}
      </div>
      <HorizontalBarsChart
        items={providers.metrics.map((provider) => ({
          label: provider.label,
          value: provider.amount,
          percentage: provider.percentage,
          barClassName:
            provider.label.toLocaleLowerCase() === "other"
              ? "bg-chart-categorical-8-subtle"
              : "bg-chart-categorical-1-subtler",
        }))}
        tooltipContent={(item) => (
          <div className="text-xs">
            <p>{item.label}</p>
            <div>
              <span>
                {formatCurrency({
                  currency,
                  amount: item.value,
                  compact: true,
                })}
              </span>
              <span> - </span>
              <span>{twoFixedDigit(item.percentage)}%</span>
            </div>
          </div>
        )}
        formatValue={(item) =>
          formatCurrency({
            currency,
            amount: item.value,
            compact: true,
          })
        }
        onBarHover={(showTooltip, item) => {
          if (showTooltip) {
            analytics.track("insights_provider_details", {
              provider: item.label,
              amount: formatCurrency({
                currency,
                amount: item.value,
                compact: true,
              }),
              percentage: `${twoFixedDigit(item.percentage)}%`,
            });
          }
        }}
      />
    </Card>
  );
};

const MethodsMetrics = ({
  methods,
  currency,
}: {
  methods: DashboardMetrics["methods"];
  currency: DashboardMetrics["currency"];
}) => {
  const { analytics } = useAnalytics();
  const highestMethod = methods.metrics[0];

  return (
    <Card disableHover>
      <div className="mb-4">
        <Heading>Methods</Heading>
        {highestMethod && (
          <Subheading>
            {twoFixedDigit(highestMethod.percentage)}% {highestMethod.label}
          </Subheading>
        )}
      </div>
      <HorizontalBarsChart
        items={methods.metrics.map((method) => ({
          label: method.label,
          value: method.amount,
          percentage: method.percentage,
          barClassName:
            method.label.toLocaleLowerCase() === "other"
              ? "bg-chart-categorical-8-subtle"
              : "bg-chart-categorical-4-subtler",
        }))}
        tooltipContent={(item) => (
          <div style={{ fontSize: "11px" }}>
            <p>{item.label}</p>
            <div>
              <span>
                {formatCurrency({
                  currency,
                  amount: item.value,
                  compact: true,
                })}
              </span>
              <span> - </span>
              <span>{twoFixedDigit(item.percentage)}%</span>
            </div>
          </div>
        )}
        formatValue={(item) =>
          formatCurrency({
            currency,
            amount: item.value,
            compact: true,
          })
        }
        onBarHover={(showTooltip, item) => {
          if (showTooltip) {
            analytics.track("insights_method_details", {
              method: item.label,
              amount: formatCurrency({
                currency,
                amount: item.value,
                compact: true,
              }),
              percentage: `${twoFixedDigit(item.percentage)}%`,
            });
          }
        }}
      />
    </Card>
  );
};

const AccountsMetrics = ({
  revenueByAccount,
  currency,
}: {
  revenueByAccount: DashboardMetrics["revenue_by_account"];
  currency: DashboardMetrics["currency"];
}) => {
  const { analytics } = useAnalytics();
  const highestMethod = revenueByAccount?.[0];

  return (
    <Card disableHover className="md:col-span-2 lg:col-span-1">
      <div className="mb-4">
        <Heading>Accounts</Heading>
        {highestMethod && (
          <Subheading>
            {twoFixedDigit(highestMethod.percentage)}% {highestMethod.label}
          </Subheading>
        )}
      </div>
      <HorizontalBarsChart
        items={
          revenueByAccount
            ? revenueByAccount.map((account) => ({
                label: account.label,
                value: account.amount,
                percentage: account.percentage,
                barClassName:
                  account.label.toLocaleLowerCase() === "other"
                    ? "bg-chart-categorical-8-subtle"
                    : "bg-chart-categorical-5-subtler",
              }))
            : []
        }
        tooltipContent={(item) => {
          return (
            <div style={{ fontSize: "11px" }}>
              <p>{item.label}</p>
              <div>
                <span>
                  {formatCurrency({
                    currency,
                    amount: item.value,
                    compact: true,
                  })}
                </span>
                <span> - </span>
                <span>{twoFixedDigit(item.percentage)}%</span>
              </div>
            </div>
          );
        }}
        formatValue={(item) =>
          formatCurrency({
            currency,
            amount: item.value,
            compact: true,
          })
        }
        onBarHover={(showTooltip, item) => {
          if (showTooltip) {
            analytics.track("insights_account_details", {
              account: item.label,
              amount: formatCurrency({
                currency,
                amount: item.value,
                compact: true,
              }),
              percentage: `${twoFixedDigit(item.percentage)}%`,
            });
          }
        }}
      />
    </Card>
  );
};

const RevenueMetrics = ({
  selectedAccounts,
  revenue,
  currency,
  totalRevenue,
}: {
  selectedAccounts: string[][];
  revenue: DashboardMetrics["revenue_by_day"];
  currency: DashboardMetrics["currency"];
  totalRevenue: DashboardMetrics["total_revenue"];
}) => {
  const [labels, data] = useMemo(
    () => [Object.keys(revenue), Object.values(revenue)],
    [revenue]
  );
  const { analytics } = useAnalytics();

  return (
    <Card disableHover className="flex flex-col">
      <div className="mb-4 flex items-center justify-between">
        <div>
          <Heading>Total Revenue</Heading>
          <Subheading>
            {formatCurrency({ currency, amount: totalRevenue })}
          </Subheading>
        </div>
        <Link
          href={{
            pathname: "/transactions",
            query: {
              account: selectedAccounts.map((account) => account[0]),
            },
          }}
          passHref
        >
          <Button
            asChild
            variant="secondary"
            size="xs"
            onClick={() => {
              analytics.track("insights_view_details", {
                source: "revenue",
              });
            }}
          >
            <a>View details</a>
          </Button>
        </Link>
      </div>
      <div className="flex h-96 flex-col">
        <Chart
          key={labels.join("")}
          type="line"
          labels={labels}
          datasets={[
            {
              label: "Total Revenue",
              data,
              borderColor: getColorFromCssVariable(
                "--chart-categorical-6-subtle"
              ),
              pointRadius: data.length === 1 ? 4 : undefined,
              backgroundColor:
                data.length === 1
                  ? getColorFromCssVariable("--chart-categorical-6-subtle")
                  : undefined,
              showLine: data.length !== 1,
            },
          ]}
          hideLegend
          onToolTipVisible={(item) => {
            analytics.track("insights_revenue_details", {
              day: item.label,
              totalRevenue: item.formattedValue,
            });
          }}
        />
      </div>
    </Card>
  );
};

const TransactionsMetrics = ({
  selectedAccounts,
  transactions,
  totalTransactions,
}: {
  selectedAccounts: string[][];
  transactions: DashboardMetrics["transactions_by_day"];
  totalTransactions: DashboardMetrics["total_transactions"];
}) => {
  const [labels, data] = useMemo(
    () => [Object.keys(transactions), Object.values(transactions)],
    [transactions]
  );
  const { analytics } = useAnalytics();

  const successColor = getColorFromCssVariable("--chart-categorical-2-subtler");
  const failColor = getColorFromCssVariable("--chart-categorical-3-subtle");
  const pendingColor = getColorFromCssVariable("--chart-categorical-4-subtler");
  return (
    <Card disableHover className="flex flex-col">
      <div className="mb-4 flex items-center justify-between">
        <div>
          <Heading>Total Transactions</Heading>
          <Subheading>{formatNumber({ value: totalTransactions })}</Subheading>
        </div>
        <Link
          href={{
            pathname: "/transactions",
            query: {
              account: selectedAccounts.map((account) => account[0]),
            },
          }}
          passHref
        >
          <Button
            asChild
            variant="secondary"
            size="xs"
            onClick={() => {
              analytics.track("insights_view_details", {
                source: "transactions",
              });
            }}
          >
            <a>View details</a>
          </Button>
        </Link>
      </div>
      <div className="flex h-96 flex-col">
        <Chart
          key={labels.join("")}
          type="bar"
          labels={labels}
          datasets={[
            {
              label: "SUCCESSFUL",
              data: data.map((d) => d.successful_count),
              backgroundColor: successColor,
              stack: "total",
            },
            {
              label: "FAILED",
              data: data.map((d) => d.failed_count),
              backgroundColor: failColor,
              stack: "total",
            },
            {
              label: "PENDING",
              data: data.map((d) => d.pending_count),
              backgroundColor: pendingColor,
              stack: "total",
            },
          ]}
          onToolTipVisible={(item) => {
            analytics.track("insights_transaction_details", {
              day: item.label,
              transactionStatus: item.datasetLabel,
              totalTransactionsCount: item.formattedValue,
            });
          }}
        />
      </div>
    </Card>
  );
};

const DeclineRateMetrics = ({
  selectedAccounts,
  rate,
}: {
  selectedAccounts: string[][];
  rate: DashboardMetrics["rate_metrics"]["decline"];
}) => {
  const { total, providers, errors } = rate;
  const { analytics } = useAnalytics();

  return (
    <Card disableHover className="flex flex-col">
      <div className="mb-4 flex h-[30px] items-center justify-between">
        <Heading className="text-foundations-boldest">Declines rate</Heading>
        <Link
          href={{
            pathname: "/transactions",
            query: {
              account: selectedAccounts.map((account) => account[0]),
            },
          }}
          passHref
        >
          <Button
            asChild
            variant="secondary"
            size="xs"
            onClick={() => {
              analytics.track("insights_view_details", {
                source: "declines",
              });
            }}
          >
            <a>View details</a>
          </Button>
        </Link>
      </div>

      <Tabs
        onTabClick={(_, tab) => {
          analytics.track("insights_tab_view", {
            source: "declines_rate",
            tab: tab.label,
          });
        }}
        tabs={[
          {
            label: "Total",
            Panel: () => (
              <div className="flex justify-center">
                <CircleChart value={total.percentage} variant="danger">
                  {!!total.diffPercentage && (
                    <DiffPercentageBadge
                      diffPercentage={total.diffPercentage}
                      variant={total?.diffPercentage > 0 ? "danger" : "success"}
                    />
                  )}
                </CircleChart>
              </div>
            ),
          },
          {
            disabled: !total.percentage,
            label: "Providers",
            Panel: () => {
              return (
                <div>
                  <p className="text-xs uppercase text-foundations-subtle">
                    Top {providers.length} Providers
                  </p>
                  <div className="mt-4 space-y-4">
                    {providers.map((provider) => (
                      <RateItem
                        key={provider.name}
                        label={provider.name}
                        img={provider.image}
                        percentage={provider.percentage}
                        diffPercentage={provider.diffPercentage}
                        variant={
                          !provider.diffPercentage ||
                          provider.diffPercentage > 0
                            ? "danger"
                            : "success"
                        }
                      />
                    ))}
                  </div>
                </div>
              );
            },
          },
          {
            disabled: !total.percentage,
            label: "Errors",
            Panel: () => (
              <div>
                <p className="text-xs uppercase text-foundations-subtle">
                  Top {errors.length} Error Codes
                </p>
                <div className="mt-4 space-y-4">
                  {errors.map((error, idx) => (
                    <RateItem
                      key={idx}
                      label={error.code}
                      percentage={error.percentage}
                      diffPercentage={error.diffPercentage}
                      variant={
                        !error.diffPercentage || error.diffPercentage > 0
                          ? "danger"
                          : "success"
                      }
                    />
                  ))}
                </div>
              </div>
            ),
          },
        ]}
      />
    </Card>
  );
};

const SuccessRateMetrics = ({
  rate,
}: {
  rate: DashboardMetrics["rate_metrics"]["success"];
}) => {
  const { total, providers, methods } = rate;
  const { analytics } = useAnalytics();
  return (
    <Card disableHover>
      <div className="mb-4 flex h-[30px] items-center">
        <Heading className="text-foundations-boldest">Success rate</Heading>
      </div>
      <Tabs
        onTabClick={(_, tab) => {
          analytics.track("insights_tab_view", {
            source: "success_rate",
            tab: tab.label,
          });
        }}
        tabs={[
          {
            label: "Total",
            Panel: () => (
              <div className="flex justify-center">
                <CircleChart value={total.percentage} variant="success">
                  {!!total.diffPercentage && (
                    <DiffPercentageBadge
                      diffPercentage={total.diffPercentage}
                      variant={total?.diffPercentage > 0 ? "success" : "danger"}
                    />
                  )}
                </CircleChart>
              </div>
            ),
          },
          {
            disabled: !total.percentage,
            label: "Providers",
            Panel: () => {
              return (
                <div>
                  <p className="text-xs uppercase text-foundations-subtle">
                    Top {providers.length} Providers
                  </p>
                  <div className="mt-4 space-y-4">
                    {providers.map((provider) => (
                      <RateItem
                        key={provider.name}
                        label={provider.name}
                        img={provider.image}
                        percentage={provider.percentage}
                        diffPercentage={provider.diffPercentage}
                        variant={
                          !provider.diffPercentage ||
                          provider.diffPercentage > 0
                            ? "success"
                            : "danger"
                        }
                      />
                    ))}
                  </div>
                </div>
              );
            },
          },
          {
            disabled: !total.percentage,
            label: "Methods",
            Panel: () => {
              return (
                <div>
                  <p className="text-xs uppercase text-foundations-subtle">
                    Top {methods.length} payment methods
                  </p>
                  <div className="mt-4 space-y-4">
                    {methods.map((method) => (
                      <RateItem
                        key={method.name}
                        label={method.name}
                        img={method.image}
                        percentage={method.percentage}
                        diffPercentage={method.diffPercentage}
                        variant={
                          !method.diffPercentage || method.diffPercentage > 0
                            ? "success"
                            : "danger"
                        }
                      />
                    ))}
                  </div>
                </div>
              );
            },
          },
        ]}
      />
    </Card>
  );
};

const RefundRateMetrics = ({
  rate,
}: {
  rate: DashboardMetrics["rate_metrics"]["refund"];
}) => {
  const { total } = rate;
  return (
    <Card disableHover>
      <div className="mb-4 flex h-[30px] items-center">
        <Heading className="text-foundations-boldest">Refund rate</Heading>
      </div>
      <Tabs
        tabs={[
          {
            label: "Total",
            Panel: () => (
              <div className="flex justify-center">
                <CircleChart value={total.percentage}>
                  {!!total.diffPercentage && (
                    <DiffPercentageBadge
                      diffPercentage={total.diffPercentage}
                    />
                  )}
                </CircleChart>
              </div>
            ),
          },
        ]}
      />
    </Card>
  );
};

const getAccountsCount = (): Promise<number> =>
  axiosApiInstance
    .get<{ count: number }>(`${process.env.NEXT_PUBLIC_API_BASE}/d/accounts/`, {
      params: {
        search: "",
        offset: 0,
        limit: 10,
      },
    })
    .then((res) => {
      return res.data.count;
    })
    .catch((err) => {
      return Promise.reject(parseErrorsToArray(err)?.[0]);
    });

const getAccountsWithSearch = (
  search?: string
): Promise<DropdownOption<string>[]> =>
  axiosApiInstance
    .get<{ data: { columns: { id: string; name: string } }[] }>(
      `${process.env.NEXT_PUBLIC_API_BASE}/d/accounts/`,
      {
        params: {
          search,
          offset: 0,
          limit: 10,
        },
      }
    )
    .then((res) => {
      if (!res.data?.data?.length) {
        return [];
      }

      return res.data.data.map(({ columns }) => [
        columns.id,
        columns.name,
      ]) as DropdownOption<string>[];
    })
    .catch((err) => {
      return Promise.reject(parseErrorsToArray(err)?.[0]);
    });

const getDashboardData = ({
  startDate,
  endDate,
  accountIds,
  signal,
}: {
  startDate: Date;
  endDate: Date;
  accountIds?: string;
  signal?: AbortSignal;
}) => {
  const start = new Date(
    new Date(startDate).getTime() -
      new Date(startDate).getTimezoneOffset() * 60 * 1000
  )
    .toISOString()
    .split("T")[0];
  const end = new Date(
    new Date(endDate).getTime() -
      new Date(endDate).getTimezoneOffset() * 60 * 1000
  )
    .toISOString()
    .split("T")[0];

  return axiosApiInstance
    .get("/d/metrics/dashboard/", {
      signal,
      params: {
        start_date: start,
        end_date: end,
        account_ids: accountIds || undefined,
      },
    })
    .then((res) => res.data);
};
